import React from "react";
import Markdown from "react-markdown"

import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from '../containers/layout'


// relatedPages is out of order due to where it loads from templates/page->blog-post.js
import RelatedPages from '../components/related-pages'

import BlogPostPreviewGrid from '../components/blog-post-preview-grid'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import SearchBar from '../components/search-bar'
import MetaSchemaSEO from '../components/seo';

import * as styles from './entity.module.css'



export const query = graphql`
query AboutQuery($entityId: String!, $entitySlug: String!) { 
  site {
    siteMetadata {
    siteUrl
    }
  }
  strapiEntity( id: {eq: $entityId} ) {
    id
    name
    latitude
    longitude
    slug
    entity_type{
      schema_thing_type
    }
    description {
      data {
        description
      }
    }
    large_main_image {
      url
      caption
      alternativeText
      localFile{
        childImageSharp{
          gatsbyImageData(
            height: 400)
        }
      }
    }
    contact_information {
      ... on STRAPI__COMPONENT_CONTACT_DETAILS_WEBSITE_URL {
        url
        link_text
        text_before_link
      }
      ... on STRAPI__COMPONENT_CONTACT_DETAILS_ADDRESS {
        address
      }
      ... on STRAPI__COMPONENT_CONTACT_DETAILS_PHONE {
        phone_number
      }
      ... on STRAPI__COMPONENT_CONTACT_DETAILS_SOCIAL_MEDIA_ACCOUNT {
        account_id
        platform
      }
    }
    main_image {
      url
      caption
      alternativeText
      localFile{
        childImageSharp{
          gatsbyImageData(
            height: 100)
        }
      }
    }
  }

  mentionedPages: allStrapiPage(
      filter: {related_entities: {elemMatch: {slug: {eq: $entitySlug}}}}
      sort: {fields: updatedAt, order: DESC}
    ) {
    nodes{
      id
      slug
      page_type {
        name
        slug_prefix
      }
      title
      publishedAt
      parent {
        id
      }
      main_image {
        url
        caption
        alternativeText
        localFile{
          childImageSharp{
            gatsbyImageData(
              height: 100)
          }
        }
      }
    }
  }
  contributedPages: allStrapiPage(
      sort: {fields:updatedAt,order:DESC}, limit: 10, filter: {authors: {elemMatch: {slug: {eq: $entitySlug}}}}
    ) {
    nodes{
      id
      slug
      page_type {
        name
        slug_prefix
      }
      title
      publishedAt
      parent {
        id
      }
      main_image {
        url
        caption
        alternativeText
        localFile{
          childImageSharp{
            gatsbyImageData(
              height: 100)
          }
        }
      }
    }
  }
}
`;

const AboutEntityPage = ( {data} ) => {
  var article = data.strapiEntity;
  article.title_short = article?.name
  article.title = article?.name
  article.parent = {"slug":'about', "title":'About'}
  const image = getImage(article?.main_image?.localFile)
  const large_image = getImage(article?.large_main_image?.localFile)
  const contributedPages = data.contributedPages.nodes
  const mentionedPages = data.mentionedPages.nodes

  return (
    <Layout>
      <MetaSchemaSEO
        title = {`About `+article.name}
      />
        <div className={styles.headerCentered}>
          <h1 className={styles.title}>{article.name}</h1>
          {
            // display contact info
            article.contact_information && article.contact_information.length > 1 &&
              <>
                { article.contact_information.map(component => component && (
                  <>
                    {component?.url && <div><span>Website: <a target="_blank" href={component?.url}>{component?.url}</a></span></div>}
                    {component?.address && <div><span>Directions: <a target="_blank" href={component?.address}>{component?.address}</a></span></div>}
                  
                  { // display twitter
                  component.twitter_handle &&
                    <div>
                        Twitter <a href={`https://twitter.com/`+component.twitter_handle}>@{component.twitter_handle}</a>
                    </div>
                  }
                  { // display url
                  component.url &&
                    <div>
                      {component.text_before_link} <a href={component.url}>{component.link_text}</a>
                    </div>
                  }
                  </>
                ))}
              </>
          }
        </div>
        { article.main_image && !article.large_main_image &&
          <figure className={styles.hero}>
            <GatsbyImage image={image} alt={article.main_image.alternativeText ? article.main_image.alternativeText : article.title } />
            <figcaption className={styles.heroImageCopyright}>{article.main_image.caption}</figcaption>
          </figure>
        }
        { article.large_main_image &&
          <figure className={styles.hero}>
            <GatsbyImage image={large_image} alt={article.large_main_image.alternativeText ? article.large_main_image.alternativeText : article.title } />
            <figcaption className={styles.heroImageCopyright}>{article.large_main_image.caption}</figcaption>
          </figure>
        }
        <Container>
          { article.description && (
            <Markdown children={article.description.data.description} skipHtml={true} />
          )}
          {
          // add children posts if any
          article.pages && article.pages.length > 0 && (
              <BlogPostPreviewList
                  nodes={article.pages}
                  max_n="10"
                  page_type={article.page_type}
              />
          )}
          { process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME && process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS &&
          <SearchBar></SearchBar>
          }
          
          {article.related_pages && article.related_pages.length > 0 && (
            <RelatedPages nodes={article.related_pages}></RelatedPages>
          )}
          {mentionedPages && mentionedPages.length > 0 &&
            <div className={styles.contributedPages}>
              <h3 className={styles.headline}>Recently Mentioned</h3>
              <BlogPostPreviewGrid
                  nodes={mentionedPages}
                  max_n="10"
                  page_type={article.page_type}
              />
            </div>
          }
          {contributedPages && contributedPages.length > 0 &&
            <div className={styles.contributedPages}>
              <h3 className={styles.headline}>Recently Contributed Content</h3>
              <BlogPostPreviewList
                  nodes={contributedPages}
                  max_n="10"
                  page_type={article.page_type}
              />
            </div>
          }
        </Container>
    </Layout>
  );
};

export default AboutEntityPage;
